import { apiInstance } from '../apiInstance';
import { protectedResources } from '../../msalAuthConfig';

const headers = {
    'Content-Type': 'application/json',
};

const contentApi = {
    GetAdvisoryGroupData: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/advisoryGroup`,
            headers,
        });
    },

    GetAdvisoryGroupMasterData: async (companyId, status) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${companyId}/advisory-group-master/${status}`,
            headers,
        });
    },

    GetAdvisoryGroupRecruitmentData: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/advisory-group-recruitment`,
            headers,
        });
    },
    GetViewMemberPagesData: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/member-portal`,
            headers,
        });
    },
    GetAdvisoryGroupRepresentativeData: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/advisory-group-representativeness`,
            headers,
        });
    },
    GetAdvisoryGroupRepresentativeDetailsData: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/${id}/representativeness`,
            headers,
        });
    },
    GetAdvisoryGroupRecruitmentApplicationData: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/${id}/applications`,
            headers,
        });
    },
    AddAdvisoryGroupRecruitmentApplicationData: async (data) => {
        return await apiInstance({
            method: 'POST',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/application`,
            headers,
            data,
        });
    },
    UpdateAdvisoryGroupRecruitmentApplicationData: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/application/${data.id}`,
            headers,
            data,
        });
    },
    UpdateAdvisoryGroupRecruitmentMemberData: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/member/${data.id}`,
            headers,
            data,
        });
    },
    GetAdvisoryGroupOperationsData: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/advisory-group-operation`,
            headers,
        });
    },
    GetAdvisoryGroupFutureMeetingData: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/advisory-group-future-meetings`,
            headers,
        });
    },
    GetAdvisoryGroupPastMeetingData: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/advisory-group-past-meetings`,
            headers,
        });
    },
    GetAdvisoryGroupMasterScheduleData: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/advisory-group-master-schedule`,
            headers,
        });
    },
    GetAdvisoryGroupAnalyticsData: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/advisory-group-analytics`,
            headers,
        });
    },
    GetAdvisoryGroupMeetingDetails: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/meeting/${id}`,
            headers,
        });
    },
    GetAdvisoryGroupMeetingLocationDetails: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/location/${id}`,
            headers,
        });
    },
    UpdateAdvisoryGroupMeetingDetails: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/meeting`,
            headers,
            data,
        });
    },
    GetMeetingLocationsData: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/meeting/${id}/locations`,
            headers,
        });
    },
    GetMeetingMembersData: async (id, meetingId) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/${id}/meeting/${meetingId}/member`,
            headers,
        });
    },
    GetContractFiles: (id) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/location/${id}/contract-files`,
            headers
        })
    },
    AddMeetingLocation: async (data) => {
        const formData = new FormData();
        formData.append('aGId', data.aGId);
        formData.append('meetingId', data.meetingId);
        formData.append('companyId', data.companyId);
        formData.append('name', data.name);
        formData.append('address1', data.address1);
        formData.append('address2', data.address2 ?? '');
        formData.append('city', data.city);
        formData.append('state', data.state);
        formData.append('zipCode', data.zipCode);
        formData.append('contactName', data.contactName);
        formData.append('email', data.email ?? '');
        formData.append('phone', data.phone ?? '');
        formData.append('parking', data.parking);
        formData.append('contract', data.contract);
        formData.append('contract2', data.contract2);
        formData.append('comment', data.comment ?? '');
        formData.append('status', data.status);
        formData.append('supportStaffId', data.supportStaffId);

        return await apiInstance({
            method: 'POST',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/location`,
            headers: {
                'Content-Type':
                    'multipart/form-data; boundary=AaB03x' +
                    '--AaB03x' +
                    'Content-Disposition: file' +
                    'Content-Type: pdf' +
                    'Content-Transfer-Encoding: binary' +
                    '...data... ' +
                    '--AaB03x--',
                Accept: 'application/json',
                type: 'formData',
            },
            data: formData,
        });
    },
    UpdateMeetingLocation: async (data) => {
        const formData = new FormData();
        formData.append('id', data.id);
        formData.append('aGId', data.aGId);
        formData.append('meetingId', data.meetingId);
        formData.append('companyId', data.companyId);
        formData.append('name', data.name);
        formData.append('address1', data.address1);
        formData.append('address2', data.address2 ?? '');
        formData.append('city', data.city);
        formData.append('state', data.state);
        formData.append('zipCode', data.zipCode);
        formData.append('contactName', data.contactName);
        formData.append('email', data.email ?? '');
        formData.append('phone', data.phone ?? '');
        formData.append('parking', data.parking);
        formData.append('contract', data.contract);
        formData.append('contract2', data.contract2);
        formData.append('comment', data.comment ?? '');
        formData.append('status', data.status);
        formData.append('supportStaffId', data.supportStaffId);

        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/location/${data.id}`,
            headers: {
                'Content-Type':
                    'multipart/form-data; boundary=AaB03x' +
                    '--AaB03x' +
                    'Content-Disposition: file' +
                    'Content-Type: pdf' +
                    'Content-Transfer-Encoding: binary' +
                    '...data... ' +
                    '--AaB03x--',
                Accept: 'application/json',
                type: 'formData',
            },
            data: formData,
        });
    },
    ArchiveMeetingLocation: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/location/${data.id}/status`,
            headers,
            data,
        });
    },
    UpdateMeetingResponseStatus: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/meeting/response-statuses`,
            headers,
            data,
        });
    },
    UpdateMeetingPostStatus: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/meeting/attendance-post-statuses`,
            headers,
            data,
        });
    },
    UpdateMeetingStatus: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/meeting/${data.meetingId}/status`,
            headers,
            data,
        });
    },
    AddAttendanceLogs: async (data) => {
        return await apiInstance({
            method: 'POST',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/attendance-log`,
            headers,
            data,
        });
    },
    UpdateAttendanceLogDetails: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/attendance-log/${data.attendanceLogId}`,
            headers,
            data,
        });
    },
    GetCommentsByMeetingId: async ({ meetingId, agMemberId }) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/meeting/${meetingId}/${agMemberId}/comments`,
            headers,
        })
    },
    AddComment: async (data) => {
        const formData = new FormData();
        formData.append('agId', data.agId);
        formData.append('meetingId', data.meetingId);
        formData.append('companyId', data.companyId);
        formData.append('agMemberId', data.agMemberId);
        formData.append('userId', data.userId);
        formData.append('comment', data.comment);

        return await apiInstance({
            method: 'POST',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/meeting/comment`,
            headers,
            data: formData,
        });
    },
    UpdateMemberCommentStatus: async (data) => {
        const formData = new FormData();
        formData.append('id', data.id);
        formData.append('status', data.status);

        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/member/${data.id}/member-comments`,
            headers,
            data: formData,
        });
    },
    GetRosterByAgId: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/${id}/roster`,
            headers,
        });
    },
    GetMeetingDetailsByAgId: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/${id}/meeting-details`,
            headers,
        });
    },
    GetRepScoresByAgId: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/${id}/rep-score`,
            headers,
        });
    },
    GetMasterById: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/${id}/master`,
            headers,
        });
    },
    GetPortalDetailsByAgId: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/${id}/member-portal`,
            headers,
        })
    },
    AddPortal: async (data) => {
        const formData = new FormData();
        formData.append('agId', data.agId);
        formData.append('companyId', data.companyId);
        formData.append('portalCreatedBy', data.portalCreatedBy);
        formData.append('portalModifiedBy', data.portalModifiedBy);
        formData.append('portalDescription', data.portalDescription);
        formData.append('portalURL', encodeURIComponent(data.portalURL));

        return await apiInstance({
            method: 'POST',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/member-portal`,
            headers,
            data: formData,
        });
    },
    UpdatePortalDetails: async (data) => {
        const formData = new FormData();
        formData.append('agId', data.agId);
        formData.append('companyId', data.companyId);
        formData.append('portalCreatedBy', data.portalCreatedBy);
        formData.append('portalModifiedBy', data.portalModifiedBy);
        formData.append('portalDescription', data.portalDescription);
        formData.append('portalURL', encodeURIComponent(data.portalURL));

        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/member-portal/${data.id}`,
            headers,
            data: formData,
        });
    },
    UpdateRecruitmentMemberStatuses: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/member/statuses`,
            headers,
            data
        });
    },
    ProcessRecruitmentApplication: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/member/${data.agMemberId}/process`,
            headers,
            data
        });
    },
    GetMemberCommentsByMemberId: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/member/${id}/member-comments`,
            headers,
        });
    },
    InsertMemberComment: async (data) => {
        return await apiInstance({
            method: 'POST',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/member-comment`,
            headers,
            data
        });
    },
    GetRecruitingOutreachByAgId: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/${id}/recruiting-outreach`,
            headers,
        })
    },
    UpdateRecruitingOutreach: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/${data.id}/recruiting-outreach`,
            headers,
            data
        })
    },
    GetAdvisoryGroupSelectedMembers: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/${id}/panel-selection-members`,
            headers,
        })
    },
    GetAdvisoryGroupPotentialMembers: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/${id}/panel-potential-members`,
            headers,
        })
    },
    GetCurrentRepScore: async (id, type) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/${id}/current-rep-score/${type}`,
            headers,
        })
    },
    CalculateRepScore: async (data) => {
        return await apiInstance({
            method: 'POST',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/rep-score/calculate`,
            headers,
            data,
        })
    },
    GetRevisedRepScore: async (id, data) => {
        return await apiInstance({
            method: 'POST',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/${id}/revised-rep-score`,
            headers,
            data
        })
    },
    UpdateAdvisoryGroupRosterMemberData: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/member/${data.id}/roster`,
            headers,
            data
        });
    },
    UpdateAdvisoryGroupStatus: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/master/${data.id}/status`,
            headers,
            data
        });
    },
    GetAgMemberMeetingDetails: async (agMemberId) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/attendance-log/member/${agMemberId}`,
            headers,
        });
    },
    getAdvisoryGroupPopChar: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/advisory-group-pop-char`,
            headers,
        });
    },
    AddMeetingDetails: async (data) => {
        return await apiInstance({
            method: 'POST',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/meeting`,
            headers,
            data
        });
    },
    GetMeetingLocationSupportStaffDetailsByAgId: async (companyId, agId) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${companyId}/meeting-location-support-staff/${agId}`,
            headers,
        });
    },
    UpdateMeetingLocationSupportStaff: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/location/${data.id}/${data.supportStaffId}`,
            headers,
            data
        });
    },
    GetAdvisoryGroupSupportStaff: async (id) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/advisory-group-support-staff`,
            headers,
        });
    },
    AddAdvisoryGroupSupportStaff: async (data) => {
        return await apiInstance({
            method: 'POST',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/support-staff`,
            headers,
            data
        });
    },
    UpdateAdvisoryGroupSupportStaff: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/support-staff/${data.id}`,
            headers,
            data
        });
    },
    UpdateSupportStaffStatuses: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/support-staff/statuses`,
            headers,
            data
        });
    },
    GetCaregiverDetailsByMemberId: async (agMemberId) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/member/${agMemberId}/care-giver`,
            headers,
        });
    },
    ArchiveCaregiver: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/care-giver/statuses`,
            headers,
            data,
        });
    },
    AddCaregiver: async (data) => {
        return await apiInstance({
            method: 'POST',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/care-giver`,
            headers,
            data
        });
    },
    UpdateCaregiver: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/care-giver/${data.id}`,
            headers,
            data
        });
    },
    UpdateCaregiverAttendanceLogData: async (data) => {
        return await apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiAdvisoryGroup.endpoint}/attendance-log/${data.id}/member-care-taker`,
            headers,
            data
        });
    },
};

export default contentApi;
