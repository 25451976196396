import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {
    ALIGN, POSITIONS, PROGRAM_TYPE, US_STATE, PROGRAM_MEETING_FREQUENCY,
    PROGRAM_MEETING_TYPE, YES_NO, FORM_ACTION_TYPE, ALERT_TYPE, COHORT_STATUS
} from "../../../constants";
import { getCohorts } from "../../../ducks/cohort";
import SharedForm from "../../../components/ui/Form";
import { isObjectEmpty, optionValues, checkObjectsEqual } from "../../../utils/utils";
import { getPrograms, createProgram, copyProgram, updateProgram } from "../../../ducks/programManagement"
import messages from "../../../utils/helper/messages";
import { AppLoadContext } from '../../../components/ui/AppLoadContext';
import { getCompanyUsersById } from "../../../ducks/company";
import styles from './ProgramManagement.module.scss';
import ConfirmModal from "../../../components/ui/ConfirmModal";
import { AlertContext } from "../../../context/AlertContext";

const ProgramModal = ({ show, onCancel, action, onSubmit, onInputChange, data = null, arrObj = null, onSubmitLoading = false }) => {
    const dispatch = useDispatch();
    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);
    const currentUser = useSelector((state) => state.user.currentUser);
    const cohortsData = useSelector((state) => state.cohort.cohorts);
    const companyUserData = useSelector((state) => state.company.users);

    const [isLoading, setIsLoading] = useState(false);
    const [programDetails, setProgramDetails] = useState(null);
    const [cohortIdOptions, setCohortIdOptions] = useState(null);
    const [projectManagerIdOptions, setProjectManagerIdOptions] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            try {
                dispatch(getCohorts(currentUser?.companyId));

            } catch (error) {
                console.error("Error fetching cohorts:", error);
            }
            try {
                dispatch(getCompanyUsersById(currentUser?.companyId));

            } catch (error) {
                console.error("Error fetching company users:", error);
            }
        }
    }, [isAppLoaded, currentUser, dispatch]);

    useEffect(() => {
        if (cohortsData && companyUserData && companyUserData.length > 0) {
            let archivedCohort = [];
            if (data && data.cohortStatus === COHORT_STATUS.ARCHIVED && disableDropdownsOnActions.includes(action)) {
                // add archived cohort for display
                archivedCohort = [{ id: data?.cohortId, name: `${data?.cohortName} (archived)`}];
            }
            let cohortsDataWithBlankValue = [{ id: '', name: 'Select Cohort' }, ...archivedCohort, ...cohortsData];
            setCohortIdOptions(cohortsDataWithBlankValue?.map(item => ({ id: item.id, value: item.name })));

            const matchIndex = companyUserData.findIndex(item => item.id === currentUser?.id);
            const reorderedProjectManagerIdOptions = matchIndex !== -1
                ? [companyUserData[matchIndex], ...companyUserData.slice(0, matchIndex), ...companyUserData.slice(matchIndex + 1)]
                : companyUserData;
            setProjectManagerIdOptions([...reorderedProjectManagerIdOptions.map(item => ({ id: item.id, value: item.name }))] ?? []);
        }
    }, [data, cohortsData, companyUserData]);

    const typeOptions = optionValues(PROGRAM_TYPE);
    const marketOptions = optionValues(US_STATE);
    const meetingFrequecyOptions = optionValues(PROGRAM_MEETING_FREQUENCY);
    const meetingTypeOptions = optionValues(PROGRAM_MEETING_TYPE);
    const coreDemographicOptions = optionValues(YES_NO);
    const languagePreferenceOptions = optionValues(YES_NO);
    const geographicDispersionOptions = optionValues(YES_NO);
    const healthAccessOptions = optionValues(YES_NO);
    const customFormStyle = `${styles.formStyle} overflow-x-hidden overflow-y-auto gx-5 py-3 mb-n3 border-top border-1`;
    const disableDropdownsOnActions = [FORM_ACTION_TYPE.Edit, FORM_ACTION_TYPE.View];


    useEffect(() => {
        if (data !== null) {
            setProgramDetails({ ...data, name: action === 'Copy' ? '' : data.name });
        }
    }, [data]);

    const arr = arrObj !== null ? arrObj : [
        { key: 'name', labelName: 'Advisory Group Name', placeholderName: 'Maximum 32 characters', type: 'text', maxLength: 32, disabled: action === FORM_ACTION_TYPE.View },
        { key: 'type', labelName: 'Advisory Group Type', placeholderName: '', type: 'options', optionValues: typeOptions, disabled: disableDropdownsOnActions.includes(action) },
        { key: 'shortName', labelName: 'Short Advisory Group Name', placeholderName: 'Maximum 8 characters', type: 'text', maxLength: 8, disabled: action === FORM_ACTION_TYPE.View },
        { key: 'status', labelName: 'Advisory Group Status', placeholderName: 'New', type: 'text', disabled: true, required: false },
        ...(!disableDropdownsOnActions.includes(action) ? [{ key: 'projectManagerId', labelName: 'Project Manager', placeholderName: '', type: 'options', optionValues: projectManagerIdOptions }] : []),
        { key: 'cohortId', labelName: 'Cohort', placeholderName: '', type: 'options', optionValues: cohortIdOptions, disabled: action === FORM_ACTION_TYPE.View },
        { key: 'targetSize', labelName: 'Advisory Group Target Size', placeholderName: '', type: 'number', maxValue: 9999, disabled: action === FORM_ACTION_TYPE.View },
        { key: 'market', labelName: 'Market', placeholderName: '', type: 'options', optionValues: marketOptions, disabled: disableDropdownsOnActions.includes(action) },
        { key: 'meetingFrequency', labelName: 'Meeting Frequency', placeholderName: '', type: 'options', optionValues: meetingFrequecyOptions, disabled: action === FORM_ACTION_TYPE.View },
        { key: 'meetingType', labelName: 'Meeting Type', placeholderName: '', type: 'options', optionValues: meetingTypeOptions, disabled: action === FORM_ACTION_TYPE.View },
        { key: 'popCharCore', labelName: 'Core Demographics', placeholderName: '', type: 'options', optionValues: coreDemographicOptions, disabled: disableDropdownsOnActions.includes(action) },
        { key: 'popCharLanguage', labelName: 'Language Preferences', placeholderName: '', type: 'options', optionValues: languagePreferenceOptions, disabled: disableDropdownsOnActions.includes(action) },
        { key: 'popCharGeoDispersion', labelName: 'Geographic Dispersion', placeholderName: '', type: 'options', optionValues: geographicDispersionOptions, disabled: disableDropdownsOnActions.includes(action) },
        { key: 'popCharHealthAccess', labelName: 'Health Access', placeholderName: '', type: 'options', optionValues: healthAccessOptions, disabled: disableDropdownsOnActions.includes(action) },
        { key: 'eacPlanName', labelName: 'EAC Plan Name', placeholderName: 'Maximum 32 characters', type: 'text', maxLength: 32, disabled: true, conditional: true, dependsOn: 'type', dependsValue: ["E"] },
        { key: 'eacPlanContract', labelName: 'EAC Plan Contract', placeholderName: 'Maximum 16 characters', type: 'text', maxLength: 16, disabled: true, conditional: true, dependsOn: 'type', dependsValue: ["E"] },
        { key: 'eacPlanPDBId', labelName: 'EAC Plan PBP ID', placeholderName: 'Maximum 16 characters', type: 'text', maxLength: 16, disabled: true, conditional: true, dependsOn: 'type', dependsValue: ["E"] },
    ]


    const Submit = async (formData) => {
        let validFormData = true;

        if (validFormData) {
            setIsLoading(true);
            if (arrObj !== null) {
                onSubmit({ ...data, ...formData });
                return;
            }
            const submitData = formData;
            submitData.companyId = currentUser?.companyId;
            submitData.userId = currentUser?.id;
            if (action !== FORM_ACTION_TYPE.New) {
                submitData.id = data.id;
                submitData.status = data.status;
            }
            switch (action) {
                case FORM_ACTION_TYPE.New:
                    try {
                        dispatch(createProgram(submitData, () => {
                            showAlert(messages.success.addProgram, ALERT_TYPE.SUCCESS);
                            setIsLoading(false);
                            onSuccessHideCallback();
                            dispatch(getPrograms(currentUser?.companyId));
                        }));
                    } catch (error) {
                        setIsLoading(false);
                        showAlert(`${messages.error.addProgram} ${error}.`, ALERT_TYPE.ERROR);
                    }
                    break;
                case FORM_ACTION_TYPE.Edit:
                    submitData.type = data.type;
                    submitData.projectManagerId = data.projectManagerId;
                    submitData.cohortId = formData.cohortId;
                    submitData.market = data.market;
                    submitData.popCharCore = data.popCharCore;
                    submitData.popCharLanguage = data.popCharLanguage;
                    submitData.popCharGeoDispersion = data.popCharGeoDispersion;
                    submitData.popCharHealthAccess = data.popCharHealthAccess;
                    try {
                        dispatch(updateProgram(submitData, () => {
                            showAlert(messages.success.updateProgram, ALERT_TYPE.SUCCESS);
                            setIsLoading(false);
                            onSuccessHideCallback();
                            dispatch(getPrograms(currentUser?.companyId));
                        }));
                    } catch (error) {
                        setIsLoading(false);
                        showAlert(`${messages.error.updateProgram} ${error}.`, ALERT_TYPE.ERROR);
                    }
                    break;
                case FORM_ACTION_TYPE.Copy:
                    try {
                        dispatch(copyProgram(submitData, () => {
                            showAlert(messages.success.copyProgram, ALERT_TYPE.SUCCESS);
                            setIsLoading(false);
                            onSuccessHideCallback();
                            dispatch(getPrograms(currentUser?.companyId));
                        }));
                    } catch (error) {
                        setIsLoading(false);
                        showAlert(`${messages.error.copyProgram} ${error}.`, ALERT_TYPE.ERROR);
                    }
                    break;
            }
        }
    }

    const isValidProgramInfo = () => {
        return !isObjectEmpty(programDetails);
    }

    const handleProgramDetailsChange = (update) => {
        setProgramDetails((details) => ({ ...details, ...update }));
    }

    const onHideCallback = () => {
        if (action === FORM_ACTION_TYPE.New && isValidProgramInfo()) {
            setProgramDetails(null);
            showAlert(`Changes discarded.`, ALERT_TYPE.ERROR);
            onCancel();
        }
        else if ((action === FORM_ACTION_TYPE.Edit || action === FORM_ACTION_TYPE.Copy) && !checkObjectsEqual(data, programDetails)) {
            setConfirmModal(true);
        }
        else {
            setProgramDetails(null);
            onCancel();
        }
    }

    const onSuccessHideCallback = () => {
        setProgramDetails(null);
        onCancel();
    }

    const handleConfirmClose = () => {
        setConfirmModal(false);
    }
    const handleConfirm = () => {
        setProgramDetails(null);
        handleConfirmClose();
        onCancel();
    }

    const inputChange = (name, value) => {
        // remove archived cohort from the cohort dropdown on cohort selection change
        if (
            action === FORM_ACTION_TYPE.Edit &&
            data.cohortStatus === COHORT_STATUS.ARCHIVED &&
            name === 'cohortId' &&
            value !== data.cohortId
        ) {
            let cohortOptions = cohortIdOptions.filter(cohort => cohort.id !== data.cohortId);
            setCohortIdOptions(cohortOptions);
        }

        if (onInputChange) {
            onInputChange(name, value);
        }

        handleProgramDetailsChange({ [name]: value });
    }

    return (
        <Modal show={show} fullscreen={false} backdrop="static" onHide={onHideCallback} size="md">
            <Modal.Header closeButton>
                <Modal.Title>{action} Advisory Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-2 py-xl-2">
                    {
                        action === FORM_ACTION_TYPE.View ?
                            (
                                <SharedForm
                                    cols={12}
                                    array={arr}
                                    modelObj={data}
                                    loading={isLoading}
                                    disabled={!isValidProgramInfo()}
                                    actionBarPosition={POSITIONS.TOP}
                                    actionBarAlign={ALIGN.RIGHT}
                                    viewOnly={true}
                                    formStyle={customFormStyle}
                                />
                            ) : (
                                <SharedForm
                                    cols={12}
                                    array={arr}
                                    modelObj={data}
                                    onSubmit={Submit}
                                    onCancel={onHideCallback}
                                    onInputChanged={inputChange}
                                    loading={isLoading || onSubmitLoading}
                                    disabled={!isValidProgramInfo()}
                                    actionBarPosition={POSITIONS.TOP}
                                    actionBarAlign={ALIGN.RIGHT}
                                    formStyle={customFormStyle}
                                />
                            )
                    }
                </div>
                <ConfirmModal
                    show={confirmModal}
                    message={messages.confirm.discardProgramEdit}
                    onConfirm={handleConfirm}
                    onCancel={handleConfirmClose}
                    onHideCallback={handleConfirmClose}
                />
            </Modal.Body>
        </Modal>
    )
}

export default ProgramModal;
