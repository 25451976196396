import { Modal } from "react-bootstrap";
import SharedForm from "../../../components/ui/Form";
import {
    AG_RECRUITMENT_APPLICATION_STATUS_VALUE,
    ALERT_TYPE,
    ALIGN, APPLICATION_SUBMISSION_TYPE,
    EAC_PHONE_TYPE,
    FORM_ACTION_TYPE,
    POSITIONS,
    US_STATE,
    VALIDATIONS,
    YES_NO,
    YES_NO_INVERSE
} from '../../../constants';
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlertContext } from "../../../context/AlertContext";
import messages from "../../../utils/helper/messages";
import {
    addAdvisoryGroupRecruitmentApplicationData,
    updateAdvisoryGroupRecruitmentApplicationData,
} from '../../../ducks/advisoryGroup';
import { isObjectEmpty, optionValues, pickProps, toDate, todayEndofDay } from '../../../utils/utils';

const MemberApplicationModal = ({ show, data = null, action, onCancel }) => {
    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);

    const currentUser = useSelector((state) => state.user.currentUser);

    const [isLoading, setIsLoading] = useState(false);
    const [applicationDetails, setApplicationDetails] = useState(null);
    const [startTime, setStartTime] = useState(new Date());

    const arr = [
        {
            key: 'participateReason',
            labelName: 'Reason for Participation',
            placeholderName: 'Reason for Participation',
            type: 'textarea',
            required: false,
            valid: VALIDATIONS.String.noSpecialChars,
        },
        {
            key: 'applicationHealthPlanId',
            labelName: 'Health Plan ID',
            placeholderName: 'Health Plan ID',
            type: 'text',
            required: false,
        },
        {
            key: 'firstName',
            labelName: 'First Name',
            placeholderName: 'First Name',
            type: 'text',
            maxLength: 35,
            valid: VALIDATIONS.String.isName,
        },
        {
            key: 'lastName',
            labelName: 'Last Name',
            placeholderName: 'Last Name',
            type: 'text',
            maxLength: 35,
            valid: VALIDATIONS.String.isName,
        },
        {
            key: 'address',
            labelName: 'Address',
            placeholderName: 'Address',
            type: 'text',
            maxLength: 35,
        },
        {
            key: 'city',
            labelName: 'City',
            placeholderName: 'City',
            type: 'text',
            maxLength: 50,
        },
        {
            key: 'state',
            labelName: 'State',
            placeholderName: 'State',
            type: 'options',
            optionValues: optionValues(US_STATE),
            maxLength: 2
        },
        {
            key: 'postalCode',
            labelName: 'Zip',
            placeholderName: 'Zip',
            type: 'text',
            minLength: 5,
            maxLength: 9,
            valid: VALIDATIONS.Number.isZipCode
        },
        {
            key: 'birthday',
            labelName: 'Birthdate',
            placeholderName: '',
            type: 'date',
            maxValue: todayEndofDay()
        },
        {
            key: 'email',
            labelName: 'Email',
            placeholderName: 'Email',
            type: 'email',
            required: false,
        },
        {
            key: 'phone',
            labelName: 'Phone',
            placeholderName: 'Phone',
            type: 'tel',
            maxLength: 10,
        },
        {
            key: 'phoneType',
            labelName: 'Phone Type',
            placeholderName: 'Phone Type',
            type: 'options',
            optionValues: optionValues(EAC_PHONE_TYPE, true),
            required: false,
        },
        {
            key: 'deviceWithInternetAccess',
            labelName: 'Device with Internet Access',
            placeholderName: 'Device with Internet Access',
            type: 'text',
            required: false,
            maxLength: 16,
        },
        {
            key: 'primaryLanguage',
            labelName: 'Primary Language',
            placeholderName: 'Primary Language',
            type: 'text',
            required: false,
            maxLength: 35,
        },
        {
            key: 'needTranslator',
            labelName: 'Need Translator',
            placeholderName: 'Need Translator',
            type: 'options',
            optionValues: optionValues(YES_NO, applicationDetails?.needTranslator === undefined ||
                applicationDetails?.needTranslator === null),
            required: false,
        },
        {
            key: 'needTransportation',
            labelName: 'Need Transportation',
            placeholderName: 'Need Transportation',
            type: 'options',
            optionValues: optionValues(YES_NO, applicationDetails?.needTransportation === undefined ||
                applicationDetails?.needTransportation === null),
            required: false,
        },
        {
            key: 'specificAccommodation',
            labelName: 'Specific Accommodation',
            placeholderName: 'Specific Accommodation',
            type: 'options',
            optionValues: optionValues(YES_NO, applicationDetails?.specificAccommodation === undefined ||
                applicationDetails?.specificAccommodation === null),
            required: false,
        },
        {
            key: 'specificAccommodationNeeds',
            labelName: 'Specific Accommodation Needs',
            placeholderName: 'Specific Accommodation Needs',
            type: 'text',
            disabled: true,
            conditional: true,
            dependsOn: 'specificAccommodation',
            dependsValue: ['Y'],
            required: false,
            maxLength: 50,
        },
        {
            key: 'dietaryRestrictionsAccommodation',
            labelName: 'Dietary Restrictions',
            placeholderName: 'Dietary Restrictions',
            type: 'options',
            optionValues: optionValues(YES_NO, applicationDetails?.dietaryRestrictionsAccommodation === undefined ||
                applicationDetails?.dietaryRestrictionsAccommodation === null),
            required: false,
        },
        {
            key: 'dietaryRestrictionsNeeds',
            labelName: 'Dietary Restrictions Needs',
            placeholderName: 'Dietary Restrictions Needs',
            type: 'text',
            disabled: true,
            conditional: true,
            dependsOn: 'dietaryRestrictionsAccommodation',
            dependsValue: ['Y'],
            required: false,
            maxLength: 50,
        },
        {
            key: 'emergencyFirstName',
            labelName: 'Emergency Contact First Name',
            placeholderName: 'Emergency Contact First Name',
            type: 'text',
            required: false,
            maxLength: 35,
        },
        {
            key: 'emergencyLastName',
            labelName: 'Emergency Contact Last Name',
            placeholderName: 'Emergency Contact Last Name',
            type: 'text',
            required: false,
            maxLength: 35,
        },
        {
            key: 'relationshipEmergency',
            labelName: 'Emergency Contact Relationship',
            placeholderName: 'Emergency Contact Relationship',
            type: 'text',
            required: false,
            maxLength: 35,
        },
        {
            key: 'phoneEmergency',
            labelName: 'Emergency Contact Phone',
            placeholderName: 'Emergency Contact Phone',
            type: 'tel',
            required: false,
            maxLength: 10,
        },
        {
            key: 'phoneTypeEmergency',
            labelName: 'Emergency Contact Phone Type',
            placeholderName: 'Emergency Contact Phone Type',
            type: 'options',
            optionValues: optionValues(EAC_PHONE_TYPE, true),
            required: false,
        },
    ];

    useEffect(() => {
        setStartTime(new Date());
    }, []);

    const onHideCallback = () => {
        setApplicationDetails(null);
        onCancel();
    }

    const handleSuccess = () => {
        setIsLoading(false);
        showAlert(messages.success.updateMemberRecord, ALERT_TYPE.SUCCESS);
        onCancel();
    }

    const handleError = (error) => {
        setIsLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    }

    const handleMemberDetailsChange = (update) => {
        setApplicationDetails((details) => ({ ...details, ...update }))
    }

    const handleInputChange = (name, value) => {
        handleMemberDetailsChange({ [name]: value });
    }

    const isValidApplicationInfo = () => {
        if (isObjectEmpty(applicationDetails)) {
            return false
        }

        return true
    }

    const handleSubmit = (formData) => {
        setIsLoading(true);
        const submitData = {
            participateReason: applicationDetails.participateReason,
            firstName: applicationDetails.firstName,
            lastName: applicationDetails.lastName,
            applicationHealthPlanId: applicationDetails.applicationHealthPlanId,
            address: applicationDetails.address,
            city: applicationDetails.city,
            state: applicationDetails.state,
            postalCode: applicationDetails.postalCode,
            birthday: applicationDetails.birthday ? toDate(applicationDetails.birthday) : null,
            email: applicationDetails.email,
            phone: applicationDetails.phone,
            phoneType: applicationDetails.phoneType,
            deviceWithInternetAccess: applicationDetails.deviceWithInternetAccess,
            primaryLanguage: applicationDetails.primaryLanguage,
            needTranslator: applicationDetails.needTranslator,
            needTransportation: applicationDetails.needTransportation,
            specificAccommodation: applicationDetails.specificAccommodation,
            specificAccommodationNeeds: formData?.specificAccommodation === YES_NO_INVERSE.No ? null : applicationDetails.specificAccommodationNeeds,
            dietaryRestrictionsNeeds: formData?.dietaryRestrictionsAccommodation === YES_NO_INVERSE.No ? null : applicationDetails?.dietaryRestrictionsNeeds,
            dietaryRestrictionsAccommodation: applicationDetails.dietaryRestrictionsAccommodation,
            emergencyFirstName: applicationDetails.emergencyFirstName,
            emergencyLastName: applicationDetails.emergencyLastName,
            relationshipEmergency: applicationDetails.relationshipEmergency,
            phoneEmergency: applicationDetails.phoneEmergency,
            phoneTypeEmergency: applicationDetails.phoneTypeEmergency,
        }

        if (action === FORM_ACTION_TYPE.Edit) {
            const updateData = {
                id: data.applicationId,
                ...submitData
            }
            dispatch(updateAdvisoryGroupRecruitmentApplicationData(updateData, handleSuccess, handleError));
        } else if (action === FORM_ACTION_TYPE.New) {
            const submitDate = new Date();
            const addData = {
                ...submitData,
                aGId: data.aGId,
                companyId: data.companyId,
                submittedByExternal: null,
                submittedbyInternal: currentUser?.id,
                submissionType: APPLICATION_SUBMISSION_TYPE.PHARO_USER,
                startTimeDate: startTime.toLocaleDateString() + ' ' + startTime.toLocaleTimeString(),
                submittedTime: submitDate.toLocaleDateString() + ' ' + submitDate.toLocaleTimeString(),
                recordStatus: AG_RECRUITMENT_APPLICATION_STATUS_VALUE.Open,
                evaluationComments: null,
                evaluationUser: currentUser?.id,
            }
            dispatch(addAdvisoryGroupRecruitmentApplicationData(addData, handleSuccess, handleError));
        }
    }

    useEffect(() => {
        if (data) {
            const initialData = pickProps(data, [
                'participateReason', 'firstName', 'lastName', 'applicationHealthPlanId', 'address', 'city', 'state', 'postalCode', 'birthday', 'email', 'phone', 'phoneType',
                'deviceWithInternetAccess', 'primaryLanguage', 'needTranslator', 'needTransportation', 'specificAccommodationNeeds', 'specificAccommodation',
                'dietaryRestrictionsNeeds', 'dietaryRestrictionsAccommodation', 'emergencyFirstName', 'emergencyLastName', 'relationshipEmergency',
                'phoneEmergency', 'phoneTypeEmergency'
            ]);
            setApplicationDetails({
                ...initialData,
                birthday: toDate(initialData.birthday),
            });
        }
    }, [data]);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onHideCallback}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>AG Member Application</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-2 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={applicationDetails}
                        loading={isLoading}
                        onInputChanged={handleInputChange}
                        onSubmit={handleSubmit}
                        onCancel={onHideCallback}
                        disabled={!isValidApplicationInfo()}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText="Save"
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default MemberApplicationModal;
