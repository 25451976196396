import React, { useEffect, useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import LoadingButton from "./LoadingButton";
import {
    isPhoneNumberValid,
    parsePhoneNumber,
    isEmailValid,
    getFileExtension,
    checkObjectsEqual,
    checkWhiteSpace,
    checkValidPositiveInteger,
    checkValuesEqual,
    checkValid,
    getFormattedValue,
    readFileAsTextAndSanitize,
} from '../../utils/utils';
import { BsFillFilePdfFill } from "react-icons/bs";
import { PiFileHtmlFill, PiFileXlsFill } from "react-icons/pi";
import { FaImage } from "react-icons/fa";
import { ALIGN, MIME_TYPES, POSITIONS, VALUE } from "../../constants";
import styles from './Form.module.scss';
import PdfViewer from "../ui/PdfViewer";

function SharedForm({
    array,
    modelObj,
    onSubmit,
    onCancel,
    onInputChanged,
    cols = 4,
    useStrictPhoneValidation = true,
    loading,
    disabled = false,
    actionBarPosition = POSITIONS.BOTTOM,
    actionBarAlign = ALIGN.LEFT,
    actionBarStyle = '',
    submitButtonText = 'Submit',
    cancelButtonText = 'Cancel',
    viewOnly = false,
    formStyle = null
}) {
    const [validated, setValidated] = useState(false);
    const [imgName, setImg] = useState('');
    const [imgNameSrc, setImgSrc] = useState({});
    const [formData, setFormData] = useState(null);
    const [errors, setErrors] = useState({});
    const [formFieldsValidated, setFormFieldsValidated] = useState({});
    const [isSubmitDisable, setIsSubmitDisable] = useState(disabled);
    const defaultFileTypes = [MIME_TYPES.PNG, MIME_TYPES.JPG, MIME_TYPES.GIF];
    const formRef = useRef(null);

    useEffect(() => {
        setFormDataIfNeeded();
    }, [modelObj]);

    useEffect(() => {
        setFormFieldsValidated(validateAllRequiredFieldsPresent());
    }, [formData]);

    useEffect(() => {
        removeErrorOnOptionsDelayedLoad();
    }, [array]);

    const removeErrorOnOptionsDelayedLoad = () => {
        for (let item of array) {
            if (item.type === 'options' && item.optionValues && item.optionValues.length > 0) {
                delete errors[item.key];
            }
        }
    }

    const setFormDataIfNeeded = () => {
        if (!modelObj) {
            modelObj = {};
            for (let item of array) {
                // if option, select the first item in the array as the default value
                if (item.type === 'options') {
                    modelObj[item.key] = item.optionValues && item.optionValues[0]?.id;
                } else {
                    modelObj[item.key] = '';
                }
            }
        }

        for (let item of array) {
            if (item.type === 'file') {
                if (imgName === '' && modelObj['fileURL']) {
                    if (modelObj['fileURL'].imageSrc) {
                        setImgSrc((prevState) => ({ ...prevState, [item.key]: `${modelObj['fileURL'].imageSrc}` }));
                    } else {
                        setImgSrc((prevState) => ({ ...prevState, [item.key]: `${modelObj['fileURL']}` }));
                    }
                }
                if (imgName === '' && modelObj[item.fileURLKey]) {
                    setImgSrc((prevState) => ({ ...prevState, [item.key]: `${modelObj[item.fileURLKey]}` }));
                }
            }
            if (item.type === 'options' && (!item.optionValues || item.optionValues.length === 0)) {
                setErrors((prevErrors) => ({ ...prevErrors, [item.key]: { key: true, value: "" } }));
            }
        }
        setFormData(modelObj);
    };

    const validateFormData = (formData) => {
        for (let item of array) {
            if (item.valid !== undefined && !['tel', 'email'].includes(item.type)) {
                if (!item.required && [undefined, null, ''].includes(formData[item.key])) {
                    continue; // validate field if not mandatory and value is blank
                }
                const value = item.type === 'number' ? Number(formData[item.key]) : formData[item.key];
                const validityCheck = checkValid(value, item.valid);
                if (!validityCheck.valid) {
                    setErrors({
                        ...errors,
                        [item.key]: { key: true, value: validityCheck.error }
                    });
                    return false;
                }
            } else {
                if (['tel', 'email'].includes(item.type) && ((item.required === false && formData[item.key].length > 0) || item.required !== false)) {
                    if (item.type === 'tel' && item.key in formData && !isPhoneNumberValid(formData[item.key], useStrictPhoneValidation)) {
                        setErrors({ ...errors, [item.key]: { key: true, value: "It looks like the number entered isn't registered as a valid phone number." } });
                        return false;
                    }
                    if (item.type === 'email' && item.key in formData && !isEmailValid(formData[item.key])) {
                        setErrors({ ...errors, [item.key]: { key: true, value: "Please enter a valid email address" } });
                        return false;
                    }
                }
                else if (['number', 'text'].includes(item.type) && ![undefined, null, ''].includes(formData[item.key])) {
                    if (item.type === 'text' && item.key in formData && checkWhiteSpace(formData[item.key])) {
                        setErrors({ ...errors, [item.key]: { key: true, value: "Text cannot have a leading or trailing white space or multiple spaces." } });
                        return false;
                    }
                    if (item.type === 'number' && item.key in formData) {
                        if (!(checkValidPositiveInteger(formData[item.key]))) {
                            if (item.maxValue && formData[item.key] < item.maxValue) {
                                setErrors({ ...errors, [item.key]: { key: true, value: `Number must be a positive integer value less than ${item.maxValue}.` } });
                                return false;
                            } else {
                                setErrors({ ...errors, [item.key]: { key: true, value: `Number must be a positive integer.` } });
                                return false;
                            }
                        }
                    }
                }
            }
        }

        return true;
    }

    const transformFormDataIfNeeded = (formData) => {
        for (let item of array) {
            if ((['tel'].includes(item.type) && item.required === false && formData[item.key].length > 0) || item.required === undefined) {
                if (item.type === 'tel' && item.key in formData) {
                    formData[item.key] = parsePhoneNumber(formData[item.key]);
                }
            }
        }
    };

    const FileName = ({ icon, name }) => {
        const Icon = icon;
        return (
            <div className={`${styles.fileWrap} ${!imgName && styles.withImage}`} title={name}>
                <span className={styles.iconWrap}>
                    <Icon className="text-muted" />
                </span>
                {!imgName &&
                    <span className={`${styles.fileName} flex-grow-1 text-truncate`}>
                        {name}
                    </span>
                }
            </div>
        );
    };

    const FileTypeDisplay = ({ item }) => {
        const fileNameKey = item.fileNameKey ?? item.key;
        const fileName = formData[fileNameKey] && typeof formData[fileNameKey] === "object" ? formData[fileNameKey].name : formData[fileNameKey];
        const fileExt = getFileExtension(fileName);

        switch (fileExt) {
            case 'pdf':
                return (
                    <>
                        <FileName name={fileName} icon={BsFillFilePdfFill} />
                        <PdfViewer file={imgNameSrc[item.key]} />
                    </>
                );
            case 'html':
                return (
                    <>
                        <FileName name={fileName} icon={PiFileHtmlFill} />
                        <object
                            data={imgNameSrc[item.key]}
                            type={MIME_TYPES.HTML}
                            className="w-100 border border-1 rounded mt-2"
                        />
                    </>
                );
            case 'jpg':
            case 'png':
            case 'gif':
            case 'svg':
                return (
                    <>
                        <FileName name={fileName} icon={FaImage} />
                        <div className={`${styles.imageWrap} w-100 border border-1 bg-light rounded-top mt-2`}>
                            <img src={imgNameSrc[item.key]} alt={formData[item.key]} />
                        </div>
                    </>
                );
            case 'xls':
            case 'csv':
                return (
                    <FileName name={fileName} icon={PiFileXlsFill} />
                )
            default:
                return null;
        }
    }

    const scrollToInvalidInput = () => {
        const form = formRef.current;
        const invalidInputs = Array.from(form.querySelectorAll(':invalid, .is-invalid'));
        invalidInputs.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top);
        invalidInputs[0].scrollIntoView({ block: 'center', behavior: 'smooth' });
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            setValidated(true);
            setTimeout(scrollToInvalidInput, 10);
        } else {
            const formSubmitData = new FormData(event.currentTarget);
            const formSubmitDataObj = Object.fromEntries(formSubmitData.entries());

            if (!validateFormData(formSubmitDataObj)) {
                setValidated(false);
                setTimeout(scrollToInvalidInput, 10);
                return;
            }

            transformFormDataIfNeeded(formSubmitDataObj);

            if (onSubmit) {
                onSubmit(formSubmitDataObj);
            }
        }
    };

    const inputChanged = async (e) => {
        let { name, value, type, checked } = e.target;
        let inputValue = value;

        if (e.target.files) {
            const selectedFile = e.target.files[0];
            let sanitizedFile; // initialize
            let allowedTypes = e.target.accept.split(',');
            setImg(value);
            if (allowedTypes.some((type) => type === selectedFile.type)) {
                const MIN_FILE_SIZE = 1 // 1KB
                const MAX_FILE_SIZE = 10240 // 10MB
                const fileSizeKiloBytes = selectedFile.size / 1024
                if (fileSizeKiloBytes < MIN_FILE_SIZE) {
                    setErrors({ ...errors, [e.target.name]: { key: true, value: "File size is less than minimum limit" } });
                    setValidated(false);
                    return
                }
                if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                    setErrors({ ...errors, [e.target.name]: { key: true, value: "File size is greater than maximum limit" } });
                    setValidated(false);
                    return
                }
                if (e.target.maxLength > 0 && e.target.maxLength < selectedFile.name.length) {
                    setErrors({ ...errors, [e.target.name]: { key: true, value: `File name should contains ${e.target.maxLength} charecters or less` } });
                    setValidated(false);
                    return;
                }
                if (selectedFile && selectedFile.type === MIME_TYPES.HTML) {
                    try {
                        const sanitizedContent = await readFileAsTextAndSanitize(selectedFile);
                        const blob = new Blob([sanitizedContent], { type: MIME_TYPES.HTML });
                        sanitizedFile = new File([blob], selectedFile.name, { type: MIME_TYPES.HTML });
                    } catch (error) {
                        setErrors({ ...errors, [e.target.name]: { key: true, value: `Error reading or sanitizing file: ${error}` } });
                        setValidated(false);
                    }
                }
            }
            else {
                setErrors({ ...errors, [e.target.name]: { key: true, value: `This format is not allowed, please upload allowed file types only` } });
                setValidated(false);
                return;
            }

            let file = selectedFile.type === MIME_TYPES.HTML ? sanitizedFile : selectedFile;
            let previewSrc = URL.createObjectURL(file);
            if (onInputChanged) {
                onInputChanged(name, file);
            }
            setFormData({ ...formData, [name]: file });
            setImgSrc((prevState) => ({ ...prevState, [name]: previewSrc }));
        } else {
            let checkValues = [];
            if (type === 'checkbox') {
                let checkBoxes = document.querySelectorAll(`[name=${name}]`);
                for (let checkBox of checkBoxes) {
                    if (checkBox.checked) {
                        checkValues.push(checkBox.value);
                    }
                }
                if (!checked) value = "";
            }

            inputValue = (type === 'checkbox') ? checkValues : value;
            if (onInputChanged) {
                onInputChanged(name, inputValue);
            }

            setFormData((data) => ({ ...data, [name]: inputValue }));
        }

        setIsSubmitDisable(checkObjectsEqual(modelObj, formData, name) && checkValuesEqual(modelObj[name], inputValue));


        // remove and update if needed
        delete errors[name];
        setErrors({ ...errors });
    };

    // default require is true for all.
    const isRequired = (item) => {
        return (!((item.type === "file" && imgNameSrc[item.key]) || (item.required === false)))
    }

    const validateAllRequiredFieldsPresent = () => {
        const form = formRef.current;
        if (form) {
            const formControls = form.elements;
            const requiredFormControls = Array.from(formControls).filter(
                (item) => (item.required !== false && item.hidden !== true && item.disabled !== true && item.tagName.toLowerCase() !== 'button')
            );
            const isFormValid = [];
            if (formData != null) {
                requiredFormControls.forEach(item => {
                    if (item.name && item.name !== '') {
                        const value = formData[item.name];
                        isFormValid.push(value !== null && value !== '' && value !== undefined);
                    }
                });
                return isFormValid.every((value) => value === true);
            }
        }
        return false;
    };

    const renderFormControl = (modelObj, item) => {
        let disabled = false;
        disabled = viewOnly ? true : item.disabled || disabled;

        if (item.type === 'options') {
            if (viewOnly === false) {
                disabled = item.disabled && item.conditional ? (formData[item.dependsOn] === "" || !item.dependsValue.includes(formData[item.dependsOn])) : item.disabled;
            }

            return (
                <>
                    <Form.Select
                        disabled={disabled}
                        visible={!item.hidden}
                        name={item.key}
                        value={formData[item.key] ?? ''}
                        onChange={inputChanged}
                        required={isRequired(item)}
                        isInvalid={
                            errors[item.key]?.key &&
                            errors[item.key].key === true
                        }>
                        {item?.optionValues?.map((optionInfo) => {
                            return (
                                <option
                                    key={optionInfo.id}
                                    value={optionInfo.id}>
                                    {optionInfo.value}
                                </option>
                            );
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {errors[item.key]?.value
                            ? errors[item.key].value
                            : `Please choose a ${item.labelName}.`}
                    </Form.Control.Feedback>
                </>
            );
        } else if (item.type === 'radio' || item.type === 'checkbox') {
            const isChecked = (val) => {
                let checked;
                if (item.type === 'radio') {
                    checked = val === formData[item.key];
                }
                if (item.type === 'checkbox') {
                    checked = formData[item.key] ? formData[item.key].includes(val) : false
                }
                return checked;
            }

            return (
                <>
                    {item?.optionValues?.map((option) => {
                        return (
                            <Form.Check
                                inline
                                type={item.type}
                                name={item.key}
                                key={option.id}
                                label={option.label}
                                id={`${item.key}-${option.id}`}
                                required={isRequired(item)}
                                disabled={disabled}
                                visible={!item.hidden}
                                value={option.value}
                                onChange={inputChanged}
                                checked={isChecked(option.value)}
                            />
                        );
                    })}
                </>
            );
        } else if (item.type === 'file') {
            let allowedFileTypes =
                item.allowedTypes?.join(',') ?? defaultFileTypes.join(',');
            let extensionsArr = Object.keys(MIME_TYPES).filter((format) =>
                allowedFileTypes
                    .split(',')
                    .some((mime) => mime === MIME_TYPES[format])
            );
            let helperText = extensionsArr.join(', ');

            return (
                <>
                    <Form.Control
                        type={item.type}
                        accept={allowedFileTypes}
                        placeholder={item.placeholderName}
                        required={isRequired(item)}
                        disabled={disabled}
                        visible={!item.hidden}
                        // value={imgName}
                        onChange={inputChanged}
                        name={item.key}
                        maxLength={item.maxLength}
                        isInvalid={
                            errors[item.key]?.key &&
                            errors[item.key].key === true
                        }
                    />
                    {imgNameSrc[item.key] && <FileTypeDisplay item={item} />}
                    <Form.Text
                        muted
                        className="d-block w-100">
                        Allowed file types: {helperText}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                        {errors[item.key]?.value
                            ? errors[item.key].value
                            : `Please choose a ${item.labelName}.`}
                    </Form.Control.Feedback>
                </>
            );
        } else if (item.type === 'textarea') {
            return (
                <>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder={item.placeholderName}
                        required={isRequired(item)}
                        value={formData[item.key] ?? ''}
                        maxLength={item.maxLength ? item.maxLength : null}
                        minLength={item.minLength ? item.minLength : null}
                        onChange={inputChanged}
                        disabled={disabled}
                        visible={!item.hidden}
                        name={item.key}
                        isInvalid={
                            errors[item.key]?.key &&
                            errors[item.key].key === true
                        }
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors[item.key]?.value
                            ? errors[item.key].value
                            : `Please choose a ${item.labelName}.`}
                    </Form.Control.Feedback>
                </>
            )
        } else {
            if (viewOnly === false) {
                disabled = item.disabled && item.conditional ? (formData[item.dependsOn] === "" || !item.dependsValue.includes(formData[item.dependsOn])) : item.disabled;
            }

            let inputValue = getFormattedValue(formData[item.key], item.type);
            let minValue = item.minValue;
            let maxValue = item.maxValue;
            if (item.type === 'date') {
                minValue = item.minDepends ? formData[item.minDepends] : item.minValue;
                maxValue = item.maxDepends ? formData[item.maxDepends] : item.maxValue;
            }

            return (
                <>
                    <Form.Control
                        type={item.type}
                        placeholder={item.placeholderName}
                        required={isRequired(item)}
                        value={inputValue}
                        maxLength={item.maxLength ? item.maxLength : null}
                        minLength={item.minLength ? item.minLength : null}
                        max={maxValue ? getFormattedValue(maxValue, item.type, VALUE.Max) : null}
                        min={minValue ? getFormattedValue(minValue, item.type, VALUE.Min) : null}
                        onChange={inputChanged}
                        disabled={disabled}
                        visible={!item.hidden}
                        name={item.key}
                        isInvalid={
                            errors[item.key]?.key &&
                            errors[item.key].key === true
                        }
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors[item.key]?.value
                            ? errors[item.key].value
                            : `Please choose a valid ${item.labelName}.`}
                    </Form.Control.Feedback>
                </>
            );
        }
    };

    const FormActionsBar = () => (
        <div className={`text-${actionBarAlign} ${actionBarPosition === POSITIONS.BOTTOM ? 'mt-4' : 'mb-4'} ${actionBarStyle}`}>
            <Button variant="outline-primary" type="button" className="me-2" onClick={onCancel}>{cancelButtonText}</Button>
            <fieldset className="d-inline-flex" disabled={loading || disabled || isSubmitDisable || Object.keys(errors).length > 0 || !formFieldsValidated}>
                <LoadingButton type="submit" loading={loading} >{submitButtonText}</LoadingButton>
            </fieldset>
        </div>
    );

    // wait for form data before rendering to be set to prevent warning
    if (!formData) {
        return null;
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit} ref={formRef}>
            {
                !viewOnly && actionBarPosition === POSITIONS.TOP &&
                <FormActionsBar />
            }
            <Row className={`${formStyle} gx-3`}>
                {array.map((obj) => {
                    return (
                        <Form.Group as={Col} md={obj.cols ?? cols} controlId={`validationCustom${obj.key}`} className={`mb-3${obj.required !== false ? ' required' : ''}`} key={obj.key}
                            style={{
                                display: obj.hidden || obj.hidden && obj.conditional && (formData[obj.dependsOn] === "" || obj.dependsValue.includes(formData[obj.dependsOn])) ? 'none' : null
                            }}
                        >
                            <Form.Label className={obj.labelName === '' && 'd-none'}>{obj.labelName}</Form.Label>
                            <InputGroup hasValidation>
                                {renderFormControl(formData, obj)}
                            </InputGroup>
                        </Form.Group>
                    )
                })
                }
            </Row>
            {
                actionBarPosition === POSITIONS.BOTTOM &&
                <FormActionsBar />
            }
        </Form>
    );
}

export default SharedForm;
