import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import SharedForm from "../../../../components/ui/Form";
import {
    AG_RECRUITMENT_RETIREMENT_REASON,
    ALERT_TYPE,
    ALIGN,
    POSITIONS,
    YES_NO_INVERSE,
    YES_NO,
    AG_RECRUITMENT_MEMBER_STATUS
} from "../../../../constants";
import { AlertContext } from "../../../../context/AlertContext";
import { updateAdvisoryGroupRosterMemberData } from "../../../../ducks/advisoryGroup";
import messages from "../../../../utils/helper/messages";
import { optionValues, pickProps } from "../../../../utils/utils";

const EditRosterDetailsModal = ({ show, data, onCancel }) => {
    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);

    const currentUser = useSelector((state) => state.user.currentUser);

    const [isLoading, setIsLoading] = useState(false);
    const [memberDetails, setMemberDetails] = useState(null);
    const [isInputChanged, setIsInputChanged] = useState(false);

    const arr = [
        {
            key: 'firstName',
            labelName: 'First Name',
            placeholderName: 'First Name',
            type: 'text',
            disabled: true
        },
        {
            key: 'lastName',
            labelName: 'Last Name',
            placeholderName: 'Last Name',
            type: 'text',
            disabled: true
        },
        { 
            key: 'address',
            labelName: 'Address',
            placeholderName: 'Address',
            type: 'text',
            required: false,
            disabled: true
        },
        {
            key: 'phone',
            labelName: 'Phone',
            placeholderName: 'Phone',
            type: 'tel',
            required: false,
            disabled: true
        },
        {
            key: 'email',
            labelName: 'Email',
            placeholderName: 'Email',
            type: 'email',
            required: false,
            disabled: true
        },
        {
            key: 'memberRetiring',
            labelName: 'Member Retiring',
            placeholderName: 'Member Retiring',
            type: 'options',
            optionValues: optionValues(YES_NO),
            required: false,
        },
        {
            key: 'retirementReason',
            labelName: 'Retirement Reason',
            placeholderName: 'Retirement Reason',
            type: 'options',
            optionValues: optionValues(AG_RECRUITMENT_RETIREMENT_REASON, true),
            required: true,
            conditional: true,
            disabled: true,
            dependsOn: 'memberRetiring',
            dependsValue: [YES_NO_INVERSE.Yes]
        },
    ];

    const onHideCallback = () => {
        setMemberDetails(null);
        onCancel();
        setIsInputChanged(false);
    }

    const handleSuccess = () => {
        setIsLoading(false);
        showAlert(messages.success.updateMemberRecord, ALERT_TYPE.SUCCESS);
        onCancel();
        setIsInputChanged(false)
    }

    const handleError = (error) => {
        setIsLoading(false);
        setIsInputChanged(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    }

    const handleMemberDetailsChange = (update) => {
        setMemberDetails((details) => ({ ...details, ...update }));
    }

    const handleInputChange = (name, value) => {
        setIsInputChanged(true);
        handleMemberDetailsChange({ [name]: value });
    }

    const isValidMemberInfo = () => data?.retirementReason === memberDetails?.retirementReason && !isInputChanged;

    const handleSubmit = async (formData) => {
        if (!formData) return;

        setIsLoading(true);
        const submitData = {
            id: data.id,
            lastUsertoUpdateRecord: currentUser?.id,
            memberRetireDate: formData.memberRetiring === YES_NO_INVERSE.Yes ? new Date() : data.memberRetireDate,
            retirementReason: formData.memberRetiring === YES_NO_INVERSE.No ? null : formData.retirementReason,
            memberStatus: AG_RECRUITMENT_MEMBER_STATUS.Retired
        };
        
        dispatch(updateAdvisoryGroupRosterMemberData(submitData, handleSuccess, handleError));
        onCancel();
        setIsLoading(false);
    }

    useEffect(() => {
        if (data) {
            const initialData = pickProps(data, [
                'firstName',
                'lastName',
                'retirementReason',
                'addressLine1',
                'addressLine2',
                'phone',
                'email'
            ]);
            setMemberDetails({ ...initialData, address: `${initialData.addressLine1} ${initialData.addressLine2 ?? ''}`.trim(), memberRetiring: initialData.retirementReason === null ? YES_NO_INVERSE.No : YES_NO_INVERSE.Yes });
        }
    }, [data]);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onHideCallback}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>AG Member Record</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-2 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={memberDetails}
                        loading={isLoading}
                        onInputChanged={handleInputChange}
                        onSubmit={handleSubmit}
                        onCancel={onHideCallback}
                        disabled={isValidMemberInfo()}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText="Save"
                        viewOnly={data.memberStatus === AG_RECRUITMENT_MEMBER_STATUS.Retired}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default EditRosterDetailsModal;
