import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import SharedForm from '../../../components/ui/Form';
import {
    ALIGN,
    POSITIONS,
    US_STATE, VALIDATIONS
} from "../../../constants";
import { optionValues } from '../../../utils/utils';

const EditMemberModal = ({ loading, show, member, onConfirm, onCancel, onHideCallback }) => {
    const [disabledButton, setDisabledButton] = useState(true);
    const stateOptions = optionValues(US_STATE);

    const arr = [
        {
            key: 'excludeMember',
            labelName: '',
            type: 'checkbox',
            optionValues: [
                { id: 'exclude', label: 'Exclude from Cohort', value: 'R' },
            ],
            required: false,
        },
        {
            key: 'healthPlanMemberId',
            labelName: 'Health Plan Member Id',
            type: 'text',
            required: false
        },
        {
            key: 'firstName',
            labelName: 'Name', type: 'text'
        },
        {
            key: 'lastName',
            labelName: 'Last Name', type: 'text'
        },
        {
            key: 'addressLine1',
            labelName: 'Address', type: 'text'
        },
        { key: 'city', labelName: 'City', placeholderName: 'City', type: 'text' },
        { key: 'state', labelName: 'State', placeholderName: 'State', type: 'options', optionValues: stateOptions, minLength: 2, maxLength: 2 },
        {
            key: 'postalCode',
            labelName: 'Postal Code',
            placeholderName: 'Postal Code',
            type: 'text',
            minLength: 5,
            maxLength: 9,
            valid: VALIDATIONS.Number.isZipCode,
        },
        { key: 'county', labelName: 'County', placeholderName: 'County', type: 'int', required: false },
        { key: 'countyName', labelName: 'County Name', placeholderName: 'County Name', type: 'text', required: true },
        {
            key: 'birthday',
            labelName: 'Birthday',
            type: 'date',
        },
        {
            key: 'email',
            labelName: 'Email',
            type: 'email',
            required: false
        },
        {
            key: 'phone',
            labelName: 'Phone',
            type: 'tel',
            required: false
        },
        {
            key: 'enrollDate',
            labelName: 'Enroll Date',
            type: 'date',
            required: false
        },
        {
            key: 'terminationDate',
            labelName: 'Termination Date',
            type: 'date',
            required: false
        },
        {
            key: 'contract',
            labelName: 'Contract',
            type: 'text',
        },
        {
            key: 'pbpId',
            labelName: 'PBP ID',
            type: 'text',
            required: false
        },
        {
            key: 'salesChannelName',
            labelName: 'Sales Channel Name',
            type: 'text',
            required: false
        },
        {
            key: 'writingAgentId',
            labelName: 'Agent Id',
            type: 'text',
            required: false
        },
        {
            key: 'writingAgentName',
            labelName: 'Agent Name',
            type: 'text',
            required: false
        },
        {
            key: 'gender',
            labelName: 'Gender',
            type: 'text',
        },
        {
            key: 'primaryLanguage',
            labelName: 'Primary Language',
            type: 'text',
        },
        {
            key: 'race',
            labelName: 'Race',
            type: 'text',
            required: false
        },
        {
            key: 'ethnicity',
            labelName: 'Ethnicity',
            type: 'text',
            required: false
        },
        {
            key: 'disabilityStatus',
            labelName: 'Disability Status',
            type: 'text',
            maxLength: 1,
            required: false
        },
        {
            key: 'customerInteractionsCountPhone',
            labelName: 'Interactions Count Phone',
            type: 'number',
            required: false
        },
        {
            key: 'customerInteractionsCountEmail',
            labelName: 'InteractionsCountEmail',
            type: 'number',
            required: false
        },
        {
            key: 'customerInteractionsCountWeb',
            labelName: 'Interactions Count Web',
            type: 'number',
            required: false
        },
        {
            key: 'customerInteractionsCountBroker',
            labelName: 'Interactions Count Broker',
            type: 'number',
            required: false
        },
        {
            key: 'customerInteractionsCountOther',
            labelName: 'Interactions Count Other',
            type: 'number',
            required: false
        },
        {
            key: 'caregiverFirstName',
            labelName: 'Caregiver First Name', type: 'text', required: false
        },
        {
            key: 'caregiverMiddleName',
            labelName: 'Caregiver Middle Name', type: 'text', required: false
        },
        {
            key: 'caregiverLastName',
            labelName: 'Caregiver Last Name', type: 'text', required: false
        },
        {
            key: 'caregiverAddressLine1',
            labelName: 'Caregiver Address', type: 'text', required: false
        },
        {
            key: 'caregiverState',
            labelName: 'Caregiver State',
            placeholderName: 'Caregiver State',
            type: 'options',
            optionValues: stateOptions,
            required: false,
            minLength: 2,
            maxLength: 2
        },
        {
            key: 'caregiverPostalCode',
            labelName: 'Caregiver Postal Code',
            type: 'text',
            minLength: 5,
            maxLength: 9,
            valid: VALIDATIONS.Number.isZipCode,
            required: false
        },
        {
            key: 'caregiverEmail',
            labelName: 'Caregiver Email',
            type: 'email',
            required: false
        },
        {
            key: 'caregiverPhone',
            labelName: 'Caregiver Phone',
            type: 'tel',
            required: false
        },
        {
            key: 'caregiverStatus',
            labelName: 'Caregiver Status',
            type: 'text',
            required: false
        },
    ];

    const onSave = async (formData) => {
        onConfirm({
            id: member.id,
            ...formData,
            status:
                formData['excludeMember'] && formData['excludeMember'].length > 0
                    ? formData['excludeMember'][0]
                    : member?.status.trim(),
        });
        setDisabledButton(true);
    }

    const inputChange = () => {
        if (disabledButton === true) {
            setDisabledButton(false);
        }
    }

    const handleModalClose = () => {
        setDisabledButton(true);
        onCancel();
    }

    return (
        <Modal show={show} fullscreen={false} backdrop="static" onHide={onHideCallback} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Edit Member Record</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SharedForm
                    array={arr}
                    modelObj={member}
                    onSubmit={onSave}
                    onCancel={handleModalClose}
                    onInputChanged={inputChange}
                    cols={12}
                    useStrictPhoneValidation={false}
                    disabled={disabledButton}
                    loading={loading}
                    actionBarPosition={POSITIONS.TOP}
                    actionBarAlign={ALIGN.RIGHT}
                    submitButtonText="Update"
                />
            </Modal.Body>
        </Modal>
    )
}

export default EditMemberModal;
